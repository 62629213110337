
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { website } from '@/utils/webSideInfo'

export default defineComponent({
  setup () {
    const router = useRouter()
    const toView = (url: string) => {
      router.push({ path: url })
    }

    const activeIndex = ref('1')

    return {
      toView,
      website,
      activeIndex
    }
  }
})

