
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { rights, companyInfo } from '@/utils/webSideInfo'

export default defineComponent({
  setup () {
    const router = useRouter()
    const toView = (url: string) => {
      router.push({ path: url })
    }

    const qrCode = {
      official: require('@/assets/image/footer/qrcode-official.jpg'),
      applet: require('@/assets/image/footer/qrcode-applet.jpg'),
      gongan: require('@/assets/image/footer/gongan.png')
    }

    return {
      toView,
      qrCode,
      companyInfo,
      rights
    }
  }
})

