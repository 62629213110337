
import { defineComponent } from 'vue'
import Header from '@/components/portal/header.vue'
import Footer from '@/components/portal/footer.vue'

export default defineComponent({
  setup () {
    console.log('this is home!')
  },

  components: { Header, Footer }
})
